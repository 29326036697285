<script>
import DataTableComponent from "@/components/DataTableComponent.vue";
import {debounce} from "lodash";
import ConfirmationDialog from "@/components/ConfirmationDialogComponent.vue";
import ConfirmationDialogComponent from "@/components/ConfirmationDialogComponent.vue";
import InformacionProvedor from "@/components/InfomacionProveedorComponent.vue";
import ProgresiveLoadAutocompleteOld from "@/components/utils/ProgresiveLoadAutocompleteOld.vue";
import AdministrarContratoServices from "@/services/AdministrarContrato.services";

const servicio = AdministrarContratoServices.listarInstituciones;
export default {
  name: "ListAdminGeneracionContrato",
  components: {
    ProgresiveLoadAutocompleteOld,
    InformacionProvedor, ConfirmationDialogComponent, ConfirmationDialog, DataTableComponent
  },
  data: () => ({
    service: servicio,
    show_modal: false,
    crear_modal: false,
    form: {
      institucion: null,
      anio: null,
    },
    paginacion: {
      page: 1,
      items_per_page: 10,
      total_rows: 0,
    },
    callback: null,
    listadoInstituciones: [],
    ACTIONS_CONST: [
      {
        tooltip: {
          text: "Eliminar Institución",
          color: "blueGrayMinsal",
        },
        icon: {
          color: "red",
          name: "mdi-trash-can",
        },
        eventName: "eliminarInstitucion",
      },
    ],
    headers: [
      {
        align: "center",
        text: "Institución",
        value: "nombre",
      },
      {
        align: "center",
        text: "Año",
        value: "anio",
      },
      {
        text: "Acciones",
        value: "actions",
        align: "center",
        sortable: false,
      },
    ],
    filtros: {
      institucion: null,
      anio: null,
    },
    selects: {
      anio: [],
    },
  }),
  methods: {
    async llenarTabla() {
      let params = {
        page: this.paginacion.page,
        items_per_page: this.paginacion.items_per_page,
        institucion: this.filtros.institucion,
        anio: this.filtros.anio,
      }
      const {data, headers} = await this.services.AdministrarContratoServices.getListadoAdministracionContrato(params)
      this.listadoInstituciones = data
      this.paginacion.page = Number(headers.page)
      this.paginacion.items_per_page = Number(headers.per_page)
      this.paginacion.total_rows = Number(headers.total_rows)
    },
    async paginarTabla({cantidad_por_pagina: items_per_page, pagina: page}) {
      this.paginacion.items_per_page = items_per_page
      this.paginacion.page = page
      this.llenarTabla()
    },
    async eliminarInstitucion({id: id_institucion_anio_fiscal}) {
      this.callback = () => this.services.AdministrarContratoServices.eliminarInstitucion(id_institucion_anio_fiscal)
      this.show_modal = true
    },
    agregarFiltro() {
      this.llenarTabla();
    },
    async llenarSelects() {
      const {data} = await this.services.AdministrarContratoServices.getAnioFiscal()
      this.selects.anio = data
    },
    crearInstitucion() {
      this.crear_modal = true
    },
    limpiarModal() {
      this.form = {
        institucion: null,
        anio: null,
      }
    },
    async guardarInstitucion(){
      if(!this.form.institucion || !this.form.anio){
       this.temporalAlert({
         show: true,
         message: "Debe seleccionar una institución y un año fiscal",
         type: "warning",
        });
        return
      }
      await this.services.AdministrarContratoServices.postInstitucion(this.form)
      this.limpiarModal()
      this.crear_modal = false
      this.llenarTabla()
    }
  },
  created() {
    this.llenarTabla();
    this.llenarSelects();
    this.realizarBusqueda = debounce(this.agregarFiltro, 500)
  },
}


</script>

<template>
  <div>
    <h1>Listado de Instituciones</h1>
    <v-row class="my-3">
      <v-col cols="12" sm="6" class="d-flex gap-1">
        <v-text-field
            v-model="filtros.institucion"
            outlined
            dense
            label="Buscar Institución..."
            @input="realizarBusqueda"
            clearable
            append-icon="mdi-magnify"/>
        <v-autocomplete
            label="Año Fiscal"
            outlined
            clearable
            dense
            item-value="id"
            item-text="anio"
            v-model="filtros.anio"
            :items="selects.anio"
            @change="llenarTabla()"
            no-data-text="Sin datos..."/>
      </v-col>
      <v-col cols="12" sm="6" class="d-flex justify-end">
        <v-btn
            @click="crearInstitucion"
            color="tableAccent"
            class="white--text">
          <v-icon color="white" class="mr-1"> mdi-plus-circle</v-icon>
          Agregar Institución
        </v-btn>
      </v-col>
    </v-row>
    <data-table-component
        :headers="headers"
        :items="listadoInstituciones"
        :total_registros="paginacion.total_rows"
        @paginar="paginarTabla"
        v-models:pagina="paginacion.page"
        v-models:select="paginacion.items_per_page"
        :show_actions="true"
        :actions="ACTIONS_CONST"
        show_actions
        @eliminarInstitucion="eliminarInstitucion"
    >

    </data-table-component>
    <ConfirmationDialogComponent
        :show="show_modal"
        title="¿Deseas eliminar la institución?"
        message="El cambio es irreversible"
        btnConfirmar="Continuar"
        @close="show_modal = false"
        :callback="callback"
        @cerrar="llenarTabla()"
    />

    <app-dialog
        v-model="crear_modal"
        title="Crear Institución"
    >
      <template #content>
        <v-row class="flex-column gap-2">
          <ProgresiveLoadAutocompleteOld
              v-model="form.institucion"
              class="flex-grow-1 flex-shrink-1"
              style="min-width: 200px"
              label="Institución"
              placeholder="Seleccione una institución"
              itemText="nombre"
              itemValue="id"
              hide-details
              clearable
              :service="service"
              ref="progresive"
          />
          <v-autocomplete
              outlined
              label="Año Fiscal"
              :items="selects.anio"
              item-text="anio"
              item-value="id"
              v-model="form.anio"
          />
        </v-row>
      </template>
      <template #actions>
        <div class="mt-3">
          <v-btn
              color="red"
              @click="crear_modal = false"
              class="mr-4"
              outlined
          >
            Cerrar
          </v-btn>
          <v-btn
              color="secondary"
              @click="guardarInstitucion"
              class="mr-4"
              outlined
          >
            Guardar
          </v-btn>
        </div>
      </template>
    </app-dialog>
  </div>
</template>

<style scoped>

</style>